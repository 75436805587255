.container-fluid,
.container {
  max-width: 1200px;
}
.cardContainer {
  width: 98%;
}

.profile-card-2 {
  max-width: 100%;
  background-color: #fff;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.1);
  background-position: center;
  overflow: hidden;
  position: relative;
  margin: 10px auto;
  border-radius: 10px;
}

.github {
  /* color: #dbdbdb; */
}

.github:hover {
  /* color: rgb(255, 255, 255); */
}

.profile-card-2 img {
  transition: all linear 0.25s;
}

.profile-card-2 .profile-name {
  position: absolute;
  left: 30px;
  bottom: 70px;
  font-size: 40px;
  color: #007bff;
  text-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  transition: all linear 0.25s;
  -webkit-text-stroke: 1px black;
}

.profile-card-2 .profile-icons {
  position: absolute;
  bottom: 5px;
  right: 30px;
  color: #fff;
  transition: all linear 0.25s;
}

.profile-card-2 .profile-username {
  position: absolute;
  bottom: 50px;
  left: 30px;
  color: #007bff;
  font-size: 13px;
  font-weight: bold;
  transition: all linear 0.25s;
  -webkit-text-stroke: 0.1px black;
}
.profile-card-2 #nextArrow {
  position: absolute;
  bottom: 50%;
  right: 10px;
  font-size: 36px;
  transition: all linear 0.25s;
  color: #007bff;
}

.profile-card-2:hover #nextArrow {
  font-size: 40px;
}

.profile-card-2 .profile-icons .fa {
  margin: 5px;
}

.profile-card-2:hover img {
  filter: grayscale(100%);
}

.profile-card-2:hover .profile-name {
  bottom: 80px;
}

.profile-card-2:hover .profile-username {
  bottom: 60px;
}

.profile-card-2:hover .profile-icons {
  right: 40px;
}
