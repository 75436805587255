* {
  padding: 0;
  margin: 0;
}
body {
  background-image: url("./images/header-bg.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  overflow: hidden;
}
.navBar {
  /* background-image: -webkit-linear-gradient(top, #e8e8e8, #bcbbbc); */
  background-color: #e2e4f0;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 20px 70px;
  border-bottom: #7c83a4 1px solid;
  height: 3%;
}
.terminalContainer {
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.85); */
  margin-top: 12%;
  margin-bottom: 20%;
  border-radius: 10px;
  overflow: hidden;
  clear: both;
  /* transition: all 0.5s ease-out; */
  box-shadow: rgba(0, 0, 0, 0.8) 0px 20px 70px;
}
.terminalTab {
  position: relative;
  padding: 5px 0;
  width: 100%;
  background-color: #f8f8f8;
  background-image: -webkit-linear-gradient(top, #e8e8e8, #bcbbbc);
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.8) 0px 1px 0px;
  font-size: 0.75em;
  font-family: "Lucida Grande", sans-serif;
  border-bottom: #6a6a6a 1px solid;
  box-shadow: inset rgba(255, 255, 255, 0.7) 0px 1px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.terminalTabButtons {
  display: flex;
  justify-content: space-around;
  align-items: center;
  position: absolute;
  top: 0;
  left: 6px;
  width: 70px;
  height: 30px;
}
.terminalTabButton {
  width: 14px;
  height: 14px;
  background-color: blanchedalmond;
  border-radius: 50%;
}
#b1 {
  background-color: #ff5f56;
  cursor: pointer;
}
#b2 {
  background-color: #ffbd2e;
  cursor: pointer;
}
#b3 {
  background-color: #28ca40;
}
/* .terminalTabTitle {
} */
.terminalBody {
  font-family: "Consolas", "Courier New", "Courier";
  font-size: 1.45em;
  line-height: 1.4em;
  font-weight: 500;
  text-align: left;
  width: 100%;
  height: 550px;
  padding: 10px;
  text-shadow: #000 0px 1px 0px;
  background-color: rgba(0, 0, 0, 0.85);
  /* overflow: overlay; */
  overflow-x: hidden;
  overflow-y: scroll;
  /* transition: all 0.5s ease-out; */
  color: #f0f0f0;
}

.scrollbar::-webkit-scrollbar {
  width: 15px;
}
.scrollbar::-webkit-scrollbar-track:hover {
  background-color: #f4f4f417;
}
.scrollbar::-webkit-scrollbar-thumb {
  border-radius: 16px;
  border: solid 5px transparent;
  box-shadow: inset 0 0 10px 10px #babac0;
}
.scrollbar::-webkit-scrollbar-thumb:hover {
  border: solid 4px transparent;
  box-shadow: inset 0 0 10px 10px #a0a0a5;
}
.scrollbar::-webkit-scrollbar-corner {
  background-color: transparent;
}
.scrollbar::-webkit-scrollbar-button {
  display: none;
}
.color1 {
  color: teal;
}
.color2 {
  color: #28ca40;
}
.color3 {
  color: #ffbd2e;
}
.color4 {
  color: #ffbd2e;
}
#tAbsu {
  position: absolute;
  top: 0px;
  left: 0px;
}
#wAbsu {
  position: absolute;
  top: 30px;
  left: 30px;
  /* z-index: 1; */
}

.browserContainer {
  width: 100%;
  /* background-color: rgba(0, 0, 0, 0.85); */
  margin-top: 12%;
  margin-bottom: 20%;
  border-radius: 10px;
  overflow: hidden;
  clear: both;
  /* transition: all 0.5s ease-out; */
  box-shadow: rgba(0, 0, 0, 0.8) 0px 20px 70px;
}
.browserTab {
  display: flex;
  align-items: center;
  padding: 5px 0;
  width: 100%;
  background-color: #f8f8f8;
  background-image: -webkit-linear-gradient(top, #e8e8e8, #bcbbbc);
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.8) 0px 1px 0px;
  font-size: 0.75em;
  font-family: "Lucida Grande", sans-serif;
  border-bottom: #6a6a6a 1px solid;
  box-shadow: inset rgba(255, 255, 255, 0.7) 0px 1px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.browserTabButtons {
  display: flex;
  padding: 5px;
  justify-content: space-around;
  align-items: center;
  width: 70px;
  height: 30px;
}
.browserTabButton {
  width: 14px;
  height: 14px;
  border-radius: 50%;
}
.browserTabBrackets {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 70px;
  height: 30px;
  font-size: 30px;
  font-weight: bolder;
  font-family: Georgia, "Times New Roman", Times, serif;
}
.browserTabBracket {
  /* width: 14px;
  height: 14px; */
  border-radius: 50%;
}
.browserTabTitle {
  display: flex;
  justify-content: center;
  width: 80%;
}
.browserTabs {
  display: flex;
  position: relative;
  padding-top: 5px;
  width: 100%;
  background-color: #cacaca;
  font-size: 0.75em;
  font-family: "Lucida Grande", sans-serif;
}
.browserTab2 {
  background-color: #0d1117;
  display: flex;
  align-items: center;
  margin-left: 6px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 25%;
  height: 25px;
  color: #f0f6fc;
}
.browserTab2 > img {
  margin-left: 5px;
}
.browserTab2 > span {
  margin-left: 5px;
}
.browserNewTabBtn {
  margin-left: 10px;
  /* margin-bottom: 10px; */
  /* height: 15px; */
}
.browserBody {
  background-color: #0d1117;
  font-family: "Consolas", "Courier New", "Courier";
  font-size: 1.45em;
  line-height: 1.4em;
  font-weight: 500;
  text-align: left;
  width: 100%;
  height: 650px;
  padding: 10px;
  text-shadow: #000 0px 1px 0px;
  /* overflow: overlay; */
  overflow-x: hidden;
  overflow-y: scroll;
  /* transition: all 0.5s ease-out; */
  /* color: #f0f0f0; */
}
.links {
  display: flex;
  flex-wrap: wrap;
}
.aboutmeContainer {
  text-shadow: none;
  color: #f0f6fc;
}
.hrLine {
  border: #f0f6fc 1px solid;
  width: 90px;
  margin: auto;
}
.hexagon-gallery {
  margin: auto;
  margin-top: 50px;
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-auto-rows: 70px;
  grid-gap: 14px;
  padding-bottom: 50px;
}
.hex {
  background-color: #b7b6b2;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.hex2 {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100px;
  background-color: #e2e2e2;
  -webkit-clip-path: polygon(
    50% 0%,
    100% 25%,
    100% 75%,
    50% 100%,
    0% 75%,
    0% 25%
  );
  clip-path: polygon(50% 0%, 100% 25%, 100% 75%, 50% 100%, 0% 75%, 0% 25%);
}
.hex2:first-child {
  grid-row-start: 1;
  grid-column: 2 / span 2;
}

.hex2:nth-child(2) {
  grid-row-start: 1;
  grid-column: 4 / span 2;
}

.hex2:nth-child(3) {
  grid-row-start: 1;
  grid-column: 6 / span 2;
}

.hex2:nth-child(4) {
  grid-row-start: 2;
  grid-column: 1 / span 2;
}

.hex2:nth-child(5) {
  grid-row-start: 2;
  grid-column: 3 / span 2;
}

.hex2:nth-child(6) {
  grid-row-start: 2;
  grid-column: 5 / span 2;
}

.hex2:nth-child(7) {
  grid-row-start: 2;
  grid-column: 7 / span 2;
}

.hex2:nth-child(8) {
  grid-row-start: 3;
  grid-column: 2 / span 2;
}

.hex2:nth-child(9) {
  grid-row-start: 3;
  grid-column: 4 / span 2;
}

.hex2:nth-child(10) {
  grid-row-start: 3;
  grid-column: 6 / span 2;
}

.hexContainer {
  margin: 10px 10%;
}
.tooltip > .tooltip-inner {
  background-color: #f0f6fc;
  color: #0d1117;
}
.nav-link {
  color: #0d1117 !important;
}

@media only screen and (max-width: 600px) {
  .color4 {
    display: block;
  }
  .hexContainer {
    margin: 10px 0 !important;
    padding: 0;
  }
  .browserContainer {
    width: 93%;
  }
  .terminalContainer {
    width: 93%;
  }
  .aboutmeContainer {
    padding-left: 0px;
    padding-right: 0px;
  }
}
